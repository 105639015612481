
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import { Carousel } from 'react-responsive-carousel';


import Logo from './img/logo.png'

import { Pane, Dialog } from 'evergreen-ui'



import axios from 'axios';
import { Form, Button, Spinner } from 'react-bootstrap';
import Component from '@reactions/component'

const host = "https://api.enfirad.com/"
class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      color: false,
      colo2: false,
      get_color2: '',
      name: '',
      gov: '',
      city: '',
      phone: '',
      size: '',
      count: 1,
      s40: false,
      s1: false,
      s2: false,
      s3: false,
      s4: false,
      more: false,
      counts: [],
      items: [],
      price: 60000,
      main_photo: "",
      item: {},
      photos: []
    }

    // let value = document.getElementById('countvalue').innerHTML
    // console.log(value);

  }


  numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
    return x;
  }
  componentDidMount() {


    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const product = urlParams.get('item')
    axios.get(`https://api.enfirad.com/dashbord/shop/items?item_id=${product}`)
      .then(resp => {
        console.log(resp.data);
        this.setState({ main_photo: resp.data.items[0].main_photo, item: resp.data.items[0], photos: resp.data.items[0].photos, price: resp.data.items[0].price })



      });



  }
  check_phone(phone) {
    if (phone.length != 11) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    let result = phone.substring(0, 4);

    if (result[[0]] != 0) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[[1]] != 7) {

      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[2] != 7 && result[2] != 8 && result[2] != 9 && result[2] != 5) {

      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }
    if (result[[3]] > 6) {
      window.alert('يرجى التأكد من رقم الهاتف')

      return true
    }




    return false
  }
  submit() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const item_id = urlParams.get('item')


    document.getElementById('spinnerdiv').style.display = 'flex'
    document.getElementById('submitbtn').style.display = "none"
    let name = document.getElementById('name').value;
    let phone = document.getElementById('phone').value;
    let city = document.getElementById('city').value;
    let gov = document.getElementById('gov').value;



    if (!name) {
      window.alert('يرجى ادخال الاسم')

      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"

      return -1;


    }
    if (this.check_phone(phone)) {

      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }
    if (!gov || gov === "none") {
      window.alert('يرجى ادخال المحافظة')
      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }
    if (!city) {
      window.alert('يرجى ادخال المنطقة')
      document.getElementById('spinnerdiv').style.display = 'none'
      document.getElementById('submitbtn').style.display = "flex"
      return -1;
    }


    axios.post(host +
      `users/web/v2/orders/new/`, {
      name,
      phone,
      city,
      gov,
      item_id,
      ip: ""
    })
      .then(response => {
        window.location.href = "/done";

      })
      .catch(error => {
        window.location.href = "/done";
      });



  }




  render() {
    return (
      <div className="App">

        <main className="main">
          <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>
            <center>
              <img width={350} height={150} src={Logo} alt="" />
              <h3>The future eyes</h3>
            </center>

          </div>
          <section className="mt-60 mb-60">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="product-detail accordion-detail">
                    <div className="row mb-50">
                      <div className="col-md-6 col-sm-12 col-xs-12">

                        <Carousel autoPlay infiniteLoop >
                          <div>
                            <img  src={host + "files/" + this.state.main_photo} alt='im' />
                          </div>


                          {/* <div>
                            <img src={IMG2} alt='im' />
                          </div> */}




                        </Carousel>
                        <div className="single-social-share clearfix mt-50 mb-15">
                          <div className="mobile-social-icon wow fadeIn  mb-sm-5 mb-md-0 animated">



                          </div>
                        </div>

                      </div>
                      <div className="col-md-6 col-sm-12 col-xs-12" style={{ textAlign: "right" }} >
                        <div className="detail-info" >
                          <h2 className="title-detail"> {this.state.item.name}</h2>
                          <div style={{ width: '100%', disply: 'flex', justfyContent: 'flex-end' }}>
                            <div className="product-detail-rating" >

                              <div className="product-rate-cover text-end">
                                <div className="product-rate d-inline-block">
                                  <div className="product-rating" style={{ width: '100%' }}>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix product-price-cover">
                            <div className="product-price primary-color ">
                              <ins style={{ display: 'flex', flexDirection: 'row-reverse' }}> <div className="text-brand" style={{ color: "#0d6efd" }}>  {this.numberWithCommas(this.state.price) + " "}</div ><div style={{ fontStyle: 'italic', color: "#efab42", marginRight: "10px" }}>شامل اجور التوصيل </div>  </ins>
                              <ins style={{ display: 'flex', flexDirection: 'row-reverse' }}> <div className="text-brand"> </div ><div style={{ fontStyle: 'italic', color: "#efab42", marginRight: "10px", fontSize: "20px", marginTop: "15px" }}>  الدفع عند الاستلام </div>  </ins>


                            </div>
                          </div>
                          <div className="bt-1 border-color-1 mt-15 mb-15"></div>
                          <div className="short-desc mb-30">
                            <p>

                              {this.state.item.body}
                            </p>
                          </div>

                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="الاسم" id="name" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الاسم  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            {/* <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="المحافظة" id="gov" /> */}

                            <Form.Select id="gov" style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} >
                              <option value="none">اختر محافظة</option>
                              <option value="بغداد">بغداد</option>
                              <option value="بابل">بابل</option>
                              <option value="الأنبار">الأنبار</option>
                              <option value="البصرة">البصرة</option>
                              <option value="دهوك">دهوك</option>
                              <option value="القادسية">القادسية</option>
                              <option value="ديالى">ديالى</option>
                              <option value="ذي قار">ذي قار</option>
                              <option value="السليمانية">السليمانية</option>
                              <option value="صلاح الدين">صلاح الدين</option>
                              <option value="كركوك">كركوك</option>
                              <option value="كربلاء">كربلاء</option>
                              <option value="المثنى">المثنى</option>
                              <option value="النجف">النجف</option>
                              <option value="نينوى">نينوى</option>
                              <option value="واسط">واسط</option>
                              <option value="ميسان">ميسان</option>
                              <option value="اربيل">اربيل</option>
                              <option value="موصل">موصل</option>
                            </Form.Select>
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المحافظة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"text"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="المدينة" id="city" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>المدينة  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>
                            <input type={"number"} min={1} max={4} style={{ width: '75%', textAlign: 'right', paddingRight: 10 }} placeholder="الهاتف" id="phone" />
                            <h5 style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>الهاتف  </h5>

                          </div>
                          <div style={{ display: 'flex', marginTop: 15 }}>


                          </div>


                          <br></br>
                          <br></br>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>

                            <div id="spinnerdiv" style={{ display: 'none' }}>
                              <Button variant="primary" style={{ borderRadius: '35px' }} disabled>
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                ...  تحميل
                              </Button>
                            </div>

                            {/* <button type="submit" id="submitbtn" className="button button-add-to-cart"
  onClick={() => {
    this.submit()
    // console.log(this.state.size, this.state.get_color2);
  }}
>احجز الان</button> */}

                            <Component initialState={{ isShown: false }}>
                              {({ setState, state }) => (
                                <Pane>
                                  <Dialog
                                    isShown={state.isShown}
                                    title="تأكيد"
                                    onCloseComplete={() => setState({ isShown: false })}
                                    confirmLabel="احجز الان"
                                    onConfirm={() => {
                                      this.submit()
                                      setState({ isShown: false })
                                    }}
                                    cancelLabel="رجوع"
                                  >
                                    <p style={{ textAlign: 'end' }}>
                                      ❗❗ قبل الحجز ياريت
                                      <br></br>
                                      👱‍♂️📦 لو تعرف انه اكو شخص رح يجهز الك هذا الطلب
                                      <br></br>
                                      🚗👴 واكو سايق رح يوصل الطلب للعنوان اللي مثبته
                                      <br></br>
                                      😥😭🙏 كل هذا جهد تعب ومصاريف والموضوع مو لعبة وخلي الله بين عيونك
                                      <br></br>
                                      ✔👍💪✔  وتأكد من نفسك قبل ما تحجز انه رح تستلم الطلب ومثبت المعلومات الصحيحة
                                      <br></br>
                                      ❤😍🥰🤩 واذا متأكد من كل شي شكرا الك لان حجز من خلالنا ونتعهد الك رح يوصلك الطلب باحسن صورة
                                      <br></br>
                                      🙏🌷🌷 شكرالك
                                    </p>                                  </Dialog>
                                  <button type="submit" id="submitbtn" className="button button-add-to-cart"
                                    onClick={() => {
                                      setState({ isShown: true })
                                      //    this.submit()
                                      // console.log(this.state.size, this.state.get_color2);
                                    }}
                                  >احجز الان</button>

                                </Pane>
                              )}
                            </Component>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Home;
