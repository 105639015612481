
import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import './assets/css/main.css';
import Context from "./context";
import Footer from './footer.js';
import Home from './home'
import Home2 from './home2'

import Done from './done'
import ShippingPolicy from './shipping-policy';
import RefundPolicy from './refund-policy';
import PrivacyPolicy from './privacy-policy';
import TermsOfService from './terms-of-service';
class App extends Component {
  constructor() {
    super();
    this.state = {

    }

  }
  render() {
    return (
      <BrowserRouter >
      <Context.Provider
        value={
          { 
            value: this.state,
            actions: {
            }
          }
        }
      >
<Switch>
<Route exact path="/" component={Home} />
      <Route exact path="/item.html" component={Home2} />
      <Route exact path="/item" component={Home2} />
      <Route  path="/done" component={Done} />
      <Route  path="/RefundPolicy" component={RefundPolicy} />
      <Route  path="/PrivacyPolicy" component={PrivacyPolicy} />
      <Route  path="/TermsOfService" component={TermsOfService} />
      <Route  path="/ShippingPolicy" component={ShippingPolicy} />
      </Switch>
      <Footer/>
      </Context.Provider>
    </BrowserRouter>
    );
  }
}

export default App;




