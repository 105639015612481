/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './assets/css/main.css';
import Logo from './img/logo.png'

class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            color: false,
            colo2: false,
            get_color2: '',
            name: '',
            gov: '',
            city: '',
            phone: '',
            size: '',
            s40: false,
            s1: false,
            s2: false,
            s3: false,
            s4: false,
        }
    }



    render() {
        return (
            <div className="App">

                <main className="main">
                    <div className="page-header breadcrumb-wrap" style={{ display: 'felx', alignItems: "center", }}>
                        <center>
                              <img width={350} height={150} src={Logo} alt="" />
                            <h3>شركة عيون المستقبل</h3>
                        </center>

                    </div>
                  
                    <div class="col-12 col-lg-12" style={{padding:40}}>
                        <div class="single_product_desc">
                            <div class="product-meta-data" style={{textAlign: 'center'}}>

                                <a href="#">
                                    <h3>سياسة خاصة </h3>
                                </a>
           

                            </div>

                            <div class="short_overview my-5">
                                <div style={{textAlign: 'right'}}>
                                    <h4 style={{marginBottom:20}}>جمع المعلومات الشخصية </h4>
                                    <p style={{textAlign: 'right', fontSize: 20}}>



                                    توضح سياسة الخصوصيّة كيفية جمع واستخدام بياناتك الشخصية (تحت ظروفٍ معينةٍ) وعندما تزور الموقع ، حيث ان الموقع لا يقوم بجمع اي بياناتك الشخصية  ولا يتم حفظ اي معلومات خاصة ببطاقات الأتمانية حيث طريقة الدفع الوحيدة هي الدفع عند التوصيل فقط ليتسنى للزبون فحص المنتج قبل الستلام ودفع اي مبلغ.  تم تسجيل المعلومات فقط في حال تم طلب لشراء منتج معين حيث تم تسجيل المعلومات التالية                                    </p>

                                </div>
                                <div class="short_overview my-5">
                                    <div style={{textAlign: 'right'}}>
                                        <h4 style={{marginBottom:20}}>أمثلة على المعلومات الشخصية التي تم جمعها</h4>
                                        <ul class="list-group list-group-flush" style={{textAlign: 'right', fontSize: 20}}>
                                            <li lass="list-group-item"> اسم </li>
                                            <li lass="list-group-item">رقم هاتف </li>
                                            <li lass="list-group-item">  العنوان محافظة و مدينة </li>
                                            <li lass="list-group-item">المنتج اللذي من اختياره </li>
        
                        
                                        </ul>
                                    </div>
                                    <br></br>
                                    <h4 style={{marginBottom:20,textAlign: 'right'}}> يتم حفظ هذه المعلومات لغرض توصيل الطلب  فقط  </h4>
                                    
                                    <h5 style={{marginBottom:20,textAlign: 'right'}}>  تم حفظ معلومات الطلب لمدة لا تزيد عن 60 يوم  </h5>
                                </div>
                                <div class="short_overview my-5">
                                    <div style={{textAlign: 'right'}}>
                                        <h4 style={{marginBottom:20}}>اتصل</h4>
                                        <p style={{textAlign: 'right', fontSize: 20}}>
                                            لمزيد من المعلومات حول ممارسات الخصوصية لدينا ، إذا كانت لديك أسئلة ، أو إذا
                                            كنت ترغب في تقديم شكوى ، فيرجى الاتصال بنا عبر البريد الإلكتروني على
                                            <br></br>
                                            info@enzaal.com
                                            <p style={{textAlign: 'right', fontSize: 20}}>
                                     
                                              </p>
                                        </p>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default Home;
